import {Injectable, SimpleChanges} from '@angular/core';
import {FollowingService} from '../../../../@core/following.service';
import {PatientService} from '../../../../@core/patient.service';
import {HoverAction} from '../hover-action.interface';
import {map, switchMap, take} from 'rxjs/operators';
import {VisitService} from '../../../../@core/visit.service';
import {Patient} from '../../../../models/patient';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {forkJoin, of} from 'rxjs';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContentDefinitions} from '../../../../definitions/definitions';
import {ToastService} from '../../../../@core/toast.service';

@Injectable({
    providedIn: 'root',
})
export class PatientActionsService {
    getPatientActions(patient: Patient): HoverAction[] {
        return [
            {
                icon: 'check-circle',
                colorClass: 'bst',
                caption: 'Add to BroadStreet',
                permissions: ['PERMISSION_WRITE_PATIENTS', 'PERMISSION_WRITE_TASKS'],
                callback: () => {
                    ContentDefinitions.ADD_PATIENT_TO_BST_DIALOG.loadComponent().then(component => {
                        const modal = this.modalService.open(component, {size: 'md'});
                        modal.componentInstance.sourcePatientInput = patient;
                        modal.componentInstance.modal = modal;
                        const changes: SimpleChanges = {
                            sourcePatientInput: {currentValue: patient, firstChange: true, previousValue: undefined, isFirstChange: () => true},
                        };
                        modal.componentInstance.ngOnChanges(changes);
                    });
                },
                shouldShowAction: () => this.userAuth.hasBst$.pipe(
                    switchMap(hasBst => hasBst ? this.getBstPatients$(patient).pipe(map(x => !x?.length)) : of(false))
                ),
            },
            {
                key: 'addCensusEvent',
                icon: 'map-marker-edit',
                colorClass: 'deep',
                caption: 'Add BST Census Event',
                callback: () => {
                    this.getEditablePatient$(patient).subscribe(p => this.slidePanelService.addComponent('CENSUS_EDIT_NEW', p.id));
                },
                shouldShowAction: () => this.getEditablePatient$(patient).pipe(map(x => !!x)),
            },
            {
                icon: 'calendar-plus',
                colorClass: 'brand-3',
                caption: 'Add Visit Proposal',
                permissions: ['PERMISSION_WRITE_VISITS', 'PERMISSION_WRITE_EPISODIC_PROPOSALS', 'PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS'],
                callback: () => {
                    this.visitService.openEditProposal(patient, 'new');
                },
                shouldShowAction: () => this.userAuth.canProposeVisitForAnyone() ? of(true) : this.userAuth.canComposeForPatient$(patient),
            },
            {
                icon: 'star',
                colorClass: 'primary',
                caption: 'Follow Patient',
                permissions: ['PERMISSION_FOLLOW_PATIENTS'],
                callback: () => {
                    this.patientService.getPatientDetail(patient.id).patient$.pipe(
                        take(1)
                    ).subscribe(patient => {
                        this.followingService.setFollow(patient, !patient.is_followed);
                    });
                },
                active: () => this.followingService.getPatientIsFollowed(patient.id),
            },
        ];
    }

    getBstPatients$(patient: Patient) {
        return patient.isBst ? of([patient]) : this.patientService.getPatientDetail(patient.id).bstPatients$;
    }

    getEditablePatient$(patient: Patient) {
        return this.getBstPatients$(patient).pipe(switchMap(bstPatients => {
            if (!bstPatients?.length) return of (null);

            return forkJoin(bstPatients.map(bstPatient => this.userAuth.canEditPatient$(bstPatient))).pipe(
                map(canEdit => {
                    const i = canEdit.findIndex(x => x);
                    return i >= 0 ? bstPatients[i] : null;
                })
            );
        }));
    }

    editCensus(patient: Patient) {
        const censusEditAction = this.getPatientActions(patient).find(action => action.key === 'addCensusEvent');
        censusEditAction.shouldShowAction().pipe(take(1)).subscribe(shouldShow => {
            if (shouldShow) censusEditAction.callback();
            else this.toastService.warning('Census edit unavailable for this Patient');
        });
    }

    constructor(private patientService: PatientService,
                private followingService: FollowingService,
                private visitService: VisitService,
                private slidePanelService: SlidePanelService,
                private modalService: NgbModal,
                private userAuth: UserAuthService,
                private toastService: ToastService) {
    }
}
